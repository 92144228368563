module.exports = {
  comm: {
    home: 'Text to Speech',
    pricing: 'Pricing',
    voice_cloning: 'Voice Clone',
    api_intro: 'API',
    language: 'language:',
    select_language: 'select language',
    login: 'log in',
    login_desc: 'Login to access your TikTok Voice Generator account',
    logout: 'log out',
    google_access_error: "Unable to load Google login service",
    google_access_tip: "Please ensure you can access Google services normally, or try using a VPN",
    footer_help: 'contact us:',
    privacy_policy: 'Privacy Policy',
    terms_conditions: 'Terms & Conditions',
    about_us: 'About Us',
    voice_cloning: 'Voice Clone',
    api_intro: 'API'
  },
  txt2voice: {
    title: 'TikTok Voice Generator: Generate funny TikTok AI Voices',
    description: 'Generate and download funny TikTok voices online. Enhance your videos with over 200 styles in 20+ languages funny voice options with the TikTok Voice Generator.',
    keywords: 'TikTok TTS, TikTok Voice Generator, TikTok AI Voice Generator, TikTok text to speech',
    main_title_p01: 'TikTok Voice Generator',
    main_title_p02: 'Turn text into funny TikTok voices with our text-to-speech technology, featuring over 200 styles across 20+ languages.',
    main_textarea_holder: 'Type or paste text here',
    main_genvoice: 'Generate',
    main_generating: 'Generating',
    main_input_empty: 'please type or paste text ',
    daily_usage_limit_msg: '1) The daily maximum usage limit has been reached. Please come back tomorrow. 2) If you urgently need more usage, please email us.',
    text_max_prompt1: 'Please limit the word count to',
    text_max_prompt2: 'words or less!',
    popup_nologin_title: 'Upgrade to unlock more generations',
    popup_nologin_desc: 'Unlock more  generation time by upgrading to a premium subscription.',
    popup_nologin_btntext: 'Get 40% OFF Now',
    popup_nosub_title: 'Upgrade Your Subscription Plan',
    popup_nosub_desc: 'Unlock more generation time by upgrading to a premium subscription.',
    popup_nosub_btntext: 'Explore Subscription Plans',
    popup_sublimit_title: 'Subscription exhausted, please upgrade',
    popup_sublimit_desc: 'Enhance your experience with our premium features and unlimited access.',
    popup_sublimit_btntext: 'Get 40% OFF Now',
    about_title: 'About TikTok Voice Generator',
    preview_voice: 'Preview',
    audio_play: 'Play',
    audio_pause: 'Pause',
    audio_download: 'Download',
    audio_downloading: 'Downloading...',
    popup_download_title: 'Upgrade Your Plan to Start Downloading',
    popup_download_features: {
        unlimited: 'Unlimited Downloads',
        voices: 'Over 200 funny voices cross 20+ languages',
        voice_api: 'Voice Cloning & API',
    },
    popup_download_btn: 'Get 40% OFF Now',
    popup_download_discount: '40% OFF',
    popup_modal_features: {
      voices: ' funny voices, 20+ languages',
      api: 'Voice Cloning & API access',
      unlimited: 'Unlimited Downloads'
    },
  },
  pricing: {
    main_title: '🚀 Choose Your Plan & Power Up Your Content',
    new_features_alert: "📣 We've launched 🎙️ Voice Cloning and 🤖 API services for paid members! (After payment, email tiktokaivoicetool@gmail.com for help with these features). Additionally, feel free to email us for any customization requests😃",
    pricing_title: 'TikTok Voice Generator Plan for individual creators.',
    pricing_desc: '100+ natural, human-like voices. 15+ languages and almost the lowest pricing for everyone',
    black_friday_sale: 'Flash Sale',
    sub_free_name: 'Free',
    sub_free_desc: 'For individuals who want to try out the most advanced AI audio',
    sub_free_content: [
      '1000 characters per month limit',
      '5 generations per day',
    ],
    sub_starter_name: 'Starter',
    sub_starter_desc: 'For creators making premium content for global audiences',
    sub_starter_content: [
              '150+ voices, 20+ languages',
	    		    '150000 characters per month limit(approximately 7hours of audio)',
              'Unlimited Downloads',
              '1 voice clones',
	    		    '72h email support',
               		 ],
    sub_pro_name: 'Pro',
    sub_pro_desc: 'For creators ramping up their content production',
    sub_pro_content: [
              'API access',
              '3 voice clones',
              '150+ voices, 20+ languages',
	    	      '1000000 characters per month limit (approximately 24hours of audio)',
	    	      'Unlimited Downloads',
	    	      '48h email support',
	    	     ],
    period_year: 'Yearly',
    period_month: 'Monthly',
    period_month_short: 'mo',
    billed_year: 'Billed yearly',
    most_popular: 'MOST POPULAR',
    discount_quantity: 'Save 40%',
    buy_btntext: 'GET STARTED',
    pay_succ: 'Payment Successful!',
    pay_succ_desc: 'Thank you for your purchase. Your transaction has been completed successfully.',
    pay_succ_btntext: 'Start Using TikTok Voice',
    pay_fail: 'Payment Failed!',
    pay_fail_desc: "We apologize, but your transaction could not be processed. Please try again or reach out to our support team for assistance at tiktokaivoicetool@gmail.com.",
    pay_fail_btntext: 'Return to Homepage',
    brands_title: 'Voice content you can use anywhere',
    testimonials_title: 'What Our Users Say',
  },
  setting: {
    setting: 'Settings',
    setting_title: 'Manage your account, usage, and subscription here.',
    basic_info: 'Basic Info',
    user_name: 'Name',
    user_email: 'Email',
    user_account: 'Account',
    user_subscript: 'Current Plan',
    user_usage: 'Usage',
    manage_subscript: 'Manage Subscription',
    use_limit_promote: "You've used 5000 out of your 10000 monthly character limit.",
    not_logged_in: 'You are not logged in',
    go_to_login: 'Go to login >>',
  },
  login: {
    title: "TikTok Voice Generator",
    subtitle: "Login to access your  account",
    alreadyLoggedIn: "You are already logged in",
    goToHome: "Go to Home"
  },
  faq: {
    quest1: 'TikTok Voice Generator - Best TikTok AI Voice & text to speech tool online',
    answer1_1: "It's a tiktok AI generator tool developed by latest tiktok tts technology,it can generate almost all tiktok AI voices .",
    answer1_2: "The tiktok AI voices that will be supported soon include: derek, trickster, austin butler , news reporter , adam  ",
    answer1_3: 'Additionally, tiktok voice generator will supports more languages and accents.',
    answer1_4: 'If you need a specific one, please email me.',

    quest2: 'What are the advantages of TikTok Voice Generator?',
    answer2_1: '1. It can generate various voices, which are often used in TikTok videos.',
    answer2_2: "2. TikTok Voice Generator use latest text to speech technology that can generate human-like speech.",
    answer2_3: '3. More convenient for video editing on PC.',
    answer2_4: "4. You can use some voiceover that removed in TikTok APP now.",

    quest3: 'How to use TikTok Voice Generator?',
    answer3_1: 'It is very easy to use:',
    answer3_2: '1. Choose the language and accent.',
    answer3_3: '2. Type the text into the text box,',
    answer3_4: '3. Press the generate button and wait a few seconds,',
    answer3_5: '4. Play or download the file.',

    quest4: 'What are the most famous and funny tiktok voices in TikTok Voice Generator?',
    answer4_1: 'The most famous one is Deep voice, officially called story teller. ',
    answer4_2: "For the funniest ones, I recommend ghostface and C3PO 's voice. These are often used for dubbing funny TikTok videos because they are highly recognizable and can quickly immerse viewers.",

    quest5: 'What are the most annoying TikTok voices in TikTok Voice Generator?',
    answer5_1: "There isn't truly an 'annoying' one.",
    answer5_2: "It's more about the context and content where the effect is used that may make some people uncomfortable. For instance, some people might find Jessie voice annoying because it appeared too frequently on TikTok during a certain period. However, due to its timbre and quality, it is actually a fantastic tiktok voice effect. Additionally, the tiktok voice actor behind it is very professional and also posts her own videos on TikTok.",
    answer5_3: 'So, I suggest to listen the audio file from this tool multiple times, and patiently find the best style that suits you.',

    quest6: 'Can TikTok Voice Generator generate jessie voice?',
    answer6_1: '1. In the first dropdown menu of TikTok Voice generator tool, select English (US) accent, then in the other dropdown menu, select Jessie voice (voice lady).',
    answer6_2: '2. Type the text  into the input box and click the generate button. ',
    answer6_3: '3. After waiting about ten seconds, you will hear Jessie voice. In the operation bar, find the button with a downward arrow and click it to download.',

    quest7: 'Can TikTok Voice Generator generate C3PO voice?',
    answer7_1: '1. Select English (US) accent, then  choose C3PO voice (robot voice).',
    answer7_2: '2. Enter the text and click the generate button.',
    answer7_3: '3. After waiting a moment, you will hear the voice. ',

    quest8: 'Can TikTok Voice Generator generate ghostface voice?',
    answer8_1: '1. Choose English (US), then  choose ghostface voice.',
    answer8_2: '2. Type and click the generate button .',
    answer8_3: '3. The audio file will be generated .',

    quest9: 'How to use TikTok Voice to Generator generate siri voice?',
    answer9_1: '1. Select English (US), then click siri voice.',
    answer9_2: '2. Put the text  into the input box and press the generate button.',
    answer9_3: '3. Then the AI audio will be generated.',

    quest10: 'How can I add  the file generated in a video?',
    answer10_1: 'If you want to know how to use the official tiktok voices on TikTok, I will write a dedicated blog post',
    answer10_2: 'Here, I will explain how to publish your speech file to TikTok with TikTok Voice Generator.',
    answer10_3: '1. If you have generated the audio file on your PC and want to upload it to TikTok or any other video editing app on your phone, you need to transfer the  file to your phone. For iPhones, you can use airDrop for the transfer. For Android phones, I am not very familiar with the process, but you will definitely find methods and tools to do so.',
    answer10_4: '2. Once the  file is on your phone, you can open TikTok APP',
    answer10_5: "1. Tap the '+' button at the bottom of the interface, select the video in your phone.",
    answer10_6: '2. After uploading the video, find a square icon on the right side of the screen to enter the editing page.',
    answer10_7: "3. At the bottom of the page, find the 'add speech' button and click to select the audio file you just transferred.",
    answer10_8: '4. In this interface, you can make some adjustments to the video, and then click the button at the top right corner to publish it.',
    quest11: 'Is TikTok Voice Generator free?',
    answer11_1: 'Yes , TikTok Voice Generator is a free tiktok TTS generator.',

    quest12: 'Where can I generate funnys TikTok Voice into my  video?',
    answer12_1: 'You can visit https://tiktokvoice.net/ to use TikTok Voice Generator'
  },
  about: {
    heroLogoTitle: 'TikTok Voice Generator',
    heroLogoAlt: 'TikTok Voice Logo',
    heroTitle: 'AI Voices Made Spectacular',
    heroSubtitle: 'Transform Your Content with AI-Powered Voice Generation',

    statsTitle: 'Our Impact',
    statsSubtitle: 'Empowering Global Content Creators',
    statsActiveUsers: 'Active Users',
    statsVoicesGenerated: 'Voices Generated',
    statsVoiceOptions: 'Voice Options',

    missionTitle: 'Our Mission',
    missionDescription: 'Our mission is to democratize voice content creation, making it accessible to everyone from content creators to enterprises.',

    missionAccessibilityTitle: 'Accessibility',
    missionAccessibilityDesc: 'Making professional voice generation available to everyone',

    missionInnovationTitle: 'Innovation',
    missionInnovationDesc: 'Continuously improving our AI technology',

    missionCommunityTitle: 'Community',
    missionCommunityDesc: 'Building a supportive creator community',

    missionGlobalReachTitle: 'Global Reach',
    missionGlobalReachDesc: 'Expanding our services worldwide',

    missionSecurityTitle: 'Security',
    missionSecurityDesc: 'Ensuring data privacy and protection',

    missionGrowthTitle: 'Growth',
    missionGrowthDesc: 'Promoting continuous improvement',

    teamTitle: 'Meet Our Team',
    teamSubtitle: 'A passionate team dedicated to revolutionizing voice technology',
    teamPhotoAlt: 'Our Team',

    contactTitle: 'Contact Us',
    contactSubtitle: 'We\'d love to hear from you',
    contactEmailTitle: 'Send Email',
    contactEmailAddress: 'tiktokaivoicetool@gmail.com',
    contactSocialTitle: 'Follow Us',
    contactSocialHandle: '@Benjamin Carter',

    headTitle: 'About TikTok Voice Generator',
    headKeywords: 'TikTok Voice Generator, Text to Speech, Voice Dubbing, TikTok Content',
    headDescription: 'Learn about our mission, our work, and how to contact our TikTok voice generation service.'
  },
  api_intro: {
    headTitle: 'TikTok Voice Generator API - Enterprise Voice AI Solutions',
    headKeywords: 'TikTok Voice API, Text to Speech API, Voice AI, Enterprise Voice Solutions',
    headDescription: 'Enterprise-grade multilingual voice AI solutions for businesses. Transform your applications with natural voice synthesis in 20+ languages.',

    heroTitle: 'TikTok Voice API Service',
    heroSubtitle: 'Transform your applications with natural, fluent voice synthesis in 20+ languages',
    viewPricing: 'View Pricing',

    featureEngine: {
      title: 'High-Performance Engine',
      desc: 'State-of-the-art deep learning technology for real-time voice synthesis',
      points: [
        'Ultra-low latency response',
        'Streaming output support',
        'Scalable architecture'
      ]
    },
    featureLanguage: {
      title: 'Global Language Support',
      desc: 'Comprehensive coverage of major world languages',
      points: [
        '20+ languages and growing',
        'Multiple accent options',
        'Natural pronunciation'
      ]
    },
    featureSocial: {
      title: 'Social Media Content',
      desc: 'Professional voiceover solutions for content creators',
      points: [
        'Perfect for TikTok & YouTube',
        'Multiple voice options',
        'Batch processing capability'
      ]
    },
    featureLearning: {
      title: 'E-Learning Solutions',
      desc: 'Enhance online education with professional voice synthesis',
      points: [
        'Course content narration',
        'Language learning pronunciation',
        'Interactive learning materials'
      ]
    },
    featureService: {
      title: 'AI Customer Service',
      desc: 'Create natural, engaging voice interactions',
      points: [
        '24/7 automated service',
        'Seamless language switching',
        'Emotional voice synthesis'
      ]
    },

    integration: {
      title: 'Getting Started',
      steps: [
        {
          title: 'Choose Plan',
          desc: 'Select your PRO subscription'
        },
        {
          title: 'Contact Us',
          desc: 'Request API access via email'
        },
        {
          title: 'Get Access',
          desc: 'Receive API key & documentation'
        },
        {
          title: 'Support',
          desc: 'Dedicated integration assistance'
        }
      ]
    }
  },
  voice_cloning: {
    headTitle: 'Professional Voice Cloning Service - TikTok Voice Generator',
    headKeywords: 'Voice Cloning, AI Voice Clone, Custom Voice Generation, Voice Synthesis',
    headDescription: 'Create your unique AI voice clone with high-quality voice samples using our professional voice cloning service.',

    heroTitle: 'Professional Voice Cloning Service',
    heroSubtitle: 'Create your unique AI voice clone with high-quality voice samples',
    viewPricing: 'View Pricing',

    featureAI: {
      title: 'Advanced AI Technology',
      desc: 'Enterprise-grade voice cloning technology for precise voice replication',
      points: [
        'High-fidelity voice reproduction',
        'Natural emotion expression',
        'Professional quality output'
      ]
    },
    featureProcess: {
      title: 'Simple Process',
      desc: 'Professional voice cloning service with dedicated support',
      points: [
        'Submit 1+ minute voice sample',
        'Expert review and processing',
        'Ready for use in your account'
      ]
    },
    featureApps: {
      title: 'Versatile Applications',
      desc: 'Multiple use cases for your cloned voice',
      points: [
        'Content creation',
        'Audiobook narration',
        'Voice preservation'
      ]
    },
    featureSecurity: {
      title: 'Privacy & Security',
      desc: 'Enterprise-grade security for your voice data',
      points: [
        'Encrypted storage',
        'Secure processing',
        'Access control'
      ]
    },

    howItWorks: {
      title: 'How It Works',
      steps: [
        {
          title: 'Subscribe',
          desc: 'Choose a suitable pricing plan'
        },
        {
          title: 'Submit',
          desc: 'Contact support with your voice sample'
        },
        {
          title: 'Start Using',
          desc: 'Create content with your cloned voice'
        }
      ]
    }
  },
  soundeffect: {
    // Meta tags
    title: 'AI Sound Effect Generator:Free Text To Sound Effect',
    keywords: 'AI sound effect generator,AI sound generator,text to SFX , sound effect maker, ',
    description: 'Create unique sound effects from text  using advanced AI text to SFX technology,generate various types including nature sounds, instruments, and more.',
    
    // Hero section
    hero_title: 'AI Sound Effect Generator',
    hero_subtitle: 'Transform your text descriptions into high-quality sound effects with our AI text to sound effect technology.',
    
    // Sample section
    samples_title: 'Listen to These Realistic AI-Generated Sound Effects Below',
    
    // Generator section
    generator_title: 'Free Text To Sound Effect Generator',
    generator_subtitle: 'Choose from popular prompts below or write your own description （only English supported） to generate SFX',
    
    // Buttons
    btn_generate: 'Generate',
    btn_generating: 'Generating...',
    btn_download: 'Download',
    btn_downloading: 'Downloading...',
    
    // Prompts (显示用的翻译)
    prompt_rain: 'Rain',
    prompt_ocean: 'Ocean Waves',
    prompt_water: 'Flowing Water',
    prompt_fireworks: 'Fireworks',
    prompt_glass: 'Glass Shattering',
    prompt_magic: 'Magic Spell',
    prompt_piano: 'Piano',
    prompt_guitar: 'Electric Guitar',
    prompt_violin: 'Violin',
    prompt_baby: 'Baby Laughing',
    prompt_clapping: 'Clapping',
    prompt_celebrate: 'Celebrate',
    prompt_typing: 'Typing',
    prompt_restaurant: 'Noisy Restaurant',
    prompt_doorbell: 'Doorbell Ring',

    // Categories
    category_nature: 'Nature Sound',
    category_special: 'Special Effects',
    category_instrument: 'Instrument',
    category_human: 'Human Sound',
    category_ambient: 'Ambient Sounds',

    // Sample sounds
    sample_rain: 'Rain',
    sample_ocean: 'Ocean Waves',
    sample_water: 'Flowing Water',
    sample_thunder: 'Thunder',
    sample_insect: 'Insect Chirping',

    sample_fireworks: 'Fireworks',
    sample_glass: 'Glass Shattering',
    sample_magic: 'Magic Spell',
    sample_spaceship: 'Spaceship',
    sample_action: 'Action',

    sample_piano: 'Piano',
    sample_guitar: 'Electric Guitar',
    sample_violin: 'Violin',
    sample_keyboard: 'Electric Keyboard',
    sample_pipes: 'Irish Pipes',

    sample_baby: 'Baby Laughing',
    sample_clap: 'Clapping',
    sample_celebrate: 'Celebrate',
    sample_footsteps: 'Footsteps',
    sample_burp: 'Burping',

    sample_typing: 'Typing',
    sample_restaurant: 'Noisy Restaurant',
    sample_doorbell: 'Doorbell Ring',
    sample_tv: 'Television Playing',
    sample_cooking: 'Cooking',

    // Advantages section
    advantages_title: 'Why Choose Our AI Sound Effect Generator?',
    advantage_1_title: 'Instant Text to SFX',
    advantage_1_desc: 'Our text to SFX technology creates professional audio in seconds using natural language descriptions. Experience the fastest AI-powered text to sound effect generation.',
    advantage_2_title: 'Unlimited AI Sounds',
    advantage_2_desc: 'The AI Sound Effect Generator can create any type of sound - from nature ambience to sci-fi effects. Our AI understands and generates exactly what you need.',
    advantage_3_title: 'AI-Enhanced Quality',
    advantage_3_desc: 'Get broadcast-ready audio with our AI Sound Effect Generator. All outputs are generated in high-fidelity format using advanced AI algorithms.',
    advantage_4_title: 'Use Anywhere You Want',
    advantage_4_desc: 'Perfect for all your creative needs - from social media and YouTube videos to games, podcasts, and any other content. Our AI-generated outputs work everywhere.',

    // FAQ section
    faq_title: 'About AI Sound Effect Generator',
    faqs: {
      0: {
        question: 'What is a Text to Sound Effect Generator?',
        answer: 'Our AI Sound Effect Generator is a revolutionary tool that transforms written descriptions into custom effects using advanced AI technology. The AI Sound Effect Generator combines natural language processing with neural audio synthesis to produce high-quality output. The system uses deep learning models trained on vast audio datasets to understand complex audio characteristics and generate corresponding effects. It\'s ideal for content creators, game developers, and audio professionals who need quick access to custom effects. Our AI Sound Effect Generator processes detailed descriptions and contextual information to create nuanced, layered audio effects that match your creative vision. Whether you need environmental ambiance, mechanical noises, musical elements, or abstract effects, our system can generate them with accuracy and fidelity. This approach to audio generation offers creative possibilities through the power of artificial intelligence.'
      },
      1: {
        question: 'How does our AI Sound Effect Generator work?',
        answer: 'Our AI Sound Effect Generator uses a multi-stage process to transform text into high-quality audio. The system analyzes your text description using natural language processing algorithms to understand the desired audio characteristics. The AI Sound Effect Generator breaks down your description into key components, identifying elements like sound type, intensity, duration, and context. Our generator processes this information through neural networks trained for audio synthesis. These networks consider factors including frequency spectrum, amplitude modulation, and spatial characteristics. The AI has been trained on a database of professional audio samples, enabling it to interpret and reproduce complex sound characteristics. The AI Sound Effect Generator incorporates real-world acoustic principles to ensure naturalistic sound generation. Advanced filtering and post-processing techniques optimize the audio quality. Our system handles complex, multi-layered sound descriptions and generates corresponding audio that maintains professional quality standards.'
      },
      2: {
        question: 'What makes our AI Sound Effect Generator unique?',
        answer: 'Our AI Sound Effect Generator stands out through its combination of technological innovation, quality output, and user-friendly operation. Unlike traditional audio libraries, our AI Sound Effect Generator offers instant generation of customized effects while maintaining professional-grade quality. The AI model is trained on a dataset of high-quality audio samples, ensuring realistic and accurate results. The system\'s neural networks understand complex audio descriptions and generate effects that capture subtle nuances. Our AI Sound Effect Generator provides unlimited variations of any effect you describe, giving you creative freedom without constraints. The technology incorporates audio processing algorithms that ensure optimal frequency balance and dynamic range. Additionally, our system offers contextual understanding, allowing it to generate sounds that match your specific use case or environment.'
      },
      3: {
        question: 'How to write effective prompts for AI sound generation?',
        answer: 'Creating effective prompts for our AI Sound Effect Generator requires understanding the system\'s capabilities and sound design principles. Start with clear, specific descriptions that include multiple layers of detail about the audio you want to create. Instead of simple terms like "rain," provide rich descriptions such as "heavy rain on a metal roof with distant thunder." Include information about the environment, intensity, duration, and distinctive characteristics. Our AI Sound Effect Generator responds well to technical terms and acoustic properties, so include details about frequency ranges or dynamic variation. When describing complex sounds, break them down into components and specify how they interact. The more context and detail you provide, the better our AI Sound Effect Generator can generate what you\'re looking for. Consider the intended use of the sound effect and include relevant information about length or technical requirements.'
      },
      4: {
        question: 'How to best use AI sound effects in video content?',
        answer: 'Maximizing the impact of our AI Sound Effect Generator in video content requires a strategic approach to sound design. Analyze your video\'s emotional journey and identify key moments where custom sound effects can enhance the experience. Our AI Sound Effect Generator enables you to create effects for every scene, from subtle background ambiance to dramatic impact sounds. Layer multiple AI-generated effects to create immersive soundscapes that maintain engagement. Use our AI Sound Effect Generator to create unique transition effects that bridge scenes. The system can produce variations of similar auids to avoid repetition while maintaining consistency. For documentary-style content, generate authentic environmental sounds that enhance the sense of place. In promotional videos, create distinctive sound signatures that reinforce your brand identity. Our AI Sound Effect Generator\'s ability to produce custom effects allows you to develop unique audio elements that set your content apart.'
      },
      5: {
        question: 'How to utilize AI sound effects in game development?',
        answer: 'Our AI Sound Effect Generator offers innovative solutions for game audio design, transforming how developers approach sound creation. The AI Sound Effect Generator enables procedural variations for game actions, ensuring players never hear the same audio twice. Developers can create adaptive audio systems that respond to game states, player actions, and conditions. Our AI technology excels at producing ambient soundscapes for different environments. The AI Sound Effect Generator creates consistent audio families that maintain thematic coherence while offering variations. This is valuable for creating immersive effects, character-specific sound sets, and interactive audio. The system\'s ability to generate variations helps prevent audio fatigue during gaming sessions. Developers can use our AI Sound Effect Generator to create unique sound signatures for abilities, power-ups, and events. The technology supports layered sound effects that can be dynamically mixed based on game parameters, enabling reactive audio systems. Additionally, the generator produces specialized audio effects for different materials and interaction types, enhancing realism.'
      },
      6: {
        question: 'How to create effective white noise and ambient sounds?',
        answer: 'Define the atmosphere you want, and our AI Sound Effect Generator will create layered, full-spectrum sounds. For natural environments, specify elements like wind intensity or water movement. For urban spaces, include details about crowd density and architectural acoustics.'
      },
      7: {
        question: 'What types of sound effects can I create?',
        answer: 'Our AI Sound Effect Generator can create a wide range of sound effects, including nature sounds (rain, wind, animals), musical elements (instruments, rhythms), human sounds (footsteps, voices), mechanical effects (machines, vehicles), fantasy/sci-fi audios, and ambient atmospheres.'
      },
      8: {
        question: 'Are the AI-generated sound effects royalty-free?',
        answer: 'Yes, all outputs from our AI Sound Effect Generator are completely royalty-free and include a commercial license. You can use them in any project without additional fees or attribution requirements.'
      },
      9: {
        question: 'What is the quality of AI-generated sound effects?',
        answer: 'Our AI Sound Effect Generator produces professional-grade sound effects that meet industry standards. The output includes high-resolution audio up to 192kHz/24-bit, full frequency spectrum coverage, and carefully controlled dynamic range.'
      }
    },

    // Audio player
    audio_generation: 'Generation',
    audio_time_separator: ' / ',
  },
  host: 'https://tiktokvoice.net'
}
