import Vue from 'vue'
import VueI18n from 'vue-i18n'
import defaultMessages from './locale/english.js';

// const Vue = require('vue')
// const VueI18n = require('vue-i18n')
// const Chinese = require('./locale/chinese')
// const English = require('./locale/english')
// const Japanese = require('./locale/japanese')

Vue.use(VueI18n)

const DEFAULT_LANG = 'en'
const LOCALE_KEY = 'locale'

const localeMap = {
  zh: 'chinese',
  en: 'english',
  ja: 'japanese',
  ko: 'korean',
  vi: 'vietnamese',
  th: 'thai',
  hi: 'hindi',
  fa: 'farsi',
  ru: 'russian',
  de: 'german',
  fr: 'french',
  ro: 'romanian',
  cs: 'czech',
  es: 'spanish',
  pt: 'portuguese',
  bn: 'bengal',
  it: 'italian',
  ar: 'arabic',
  ur: 'urdu',
  'zh-tw': 'chinese_traditional',
  ms: 'malay',
  tr: 'turkish',
  pl: 'polish',
  nl: 'nederlands',
  uk: 'ukrainian'
}

export function createI18n() {
  // console.log('createI18n, 1111')
  return  new VueI18n({
    locale: DEFAULT_LANG,
    fallbackLocale: DEFAULT_LANG,
    messages: {
      [DEFAULT_LANG]: defaultMessages
    }
  })
}

const loadedLanguages = {}

export async function loadLanguageAsync(i18n, lang) {
  // console.log('loadLanguageAsync 1111, lang:', lang)
  if (lang in loadedLanguages) {
    // console.log('loadLanguageAsync 2222, lang:', lang)
    i18n.locale = lang
    i18n.setLocaleMessage(lang, loadedLanguages[lang])
    return
  }

  // 动态导入语言文件
  const mappedLang = localeMap[lang]
  // console.log('loadLanguageAsync 3333, mappedLang:', mappedLang)
  try {
    const messages = await import(
      /* webpackChunkName: "lang-[request]" */ 
      /* webpackMode: "lazy" */
      `./locale/${mappedLang}.js`)
    i18n.setLocaleMessage(lang, messages.default)
    i18n.locale = lang
    loadedLanguages[lang] = messages.default
  } catch (error) {
    console.error(`Failed to load lang:${lang}, language: ${mappedLang}`, error)
    const defaultMessages = await import(/* webpackChunkName: "lang-default" */ './locale/english.js')
    i18n.setLocaleMessage(DEFAULT_LANG, defaultMessages.default)
    i18n.locale = DEFAULT_LANG
    loadedLanguages[DEFAULT_LANG] = defaultMessages.default
  }
}
