import Vue from 'vue'
import axios from 'axios'

const host = 'https://tiktokvoice.net' // 可以从环境变量或配置文件中获取

export async function reportError(error, context) {
  try {
    const uri = `${host}/lapi/weberrors`
    const formData = new FormData()
    formData.append('error', error.message || error)
    formData.append('stack', error.stack || '') 
    formData.append('context', context)
    formData.append('url', window.location.href)
    formData.append('timestamp', new Date().toISOString())

    await axios.post(uri, formData, {
      headers: { 'Content-Type': 'multipart/form-data' }
    })
  } catch (reportError) {
    // 如果上报失败，静默处理
    ; // 什么也不做
  }
}

// 可选: 创建一个Vue插件来全局注入这个方法
export const ErrorReporterPlugin = {
  install(app) {
    // 添加全局方法
    Vue.prototype.$reportError = reportError
    
    // 添加全局错误处理
    Vue.config.errorHandler = (error, vm, info) => {
      reportError(error, `Global Error Handler: ${info}`)
    }
  }
}