import Vue from 'vue';
import Vuex from 'vuex';
import { getCookie } from '../utils/cookies';

Vue.use(Vuex);

export function createStore() {
  return new Vuex.Store({
    state: {
      // 初始状态
      urllang: '', // 从url获取语言
      lang: '',
      langsModels: {},
      allLangs: [],
      selectLang: 'english',  // select选中的语言
      selectLangModels: [],  // 选择语言的声音models
      selectModel: '',   // 选中的模型
      selectModelWavplay: '/ssr/img/converted.mp3', // 选择模型的试听声音
      count: 0,
      user: null,
      authToken: null,
      userDataChecked: false
    },
    mutations: {
      // 修改状态的方法
      setData(state, count) {
	// console.log('store setdata count1 :' + count)
        state.count = count
	// console.log('store state count2:' + state.count)
      },
     setUser(state, userData) {
       state.user = userData
     },
     setAuthToken(state, token) {
       state.authToken = token
     },
     setLang(state, lang) {
       state.lang = lang
     },
     clearUser(state) {
        state.user = null;
        state.authToken = null;
     },
     setUserDataChecked(state, value) {
      state.userDataChecked = value;
     }  
    },
    actions: {
      // 异步操作和业务逻辑
      fetchData({ commit }) {
	// console.log('store fetch data!')
        return new Promise((resolve, reject) => {
	        commit('setData', 1) 
	        resolve()
	      });
      },
      loginUser({ commit }, userData) {
        commit('setUser', userData)
      },
      logoutUser({ commit }) {
        commit('clearUser')
      },
      async checkAndLoadUserData({ commit, dispatch }, { req, isServer }) {
        let userData = null;
        if (isServer) {
          // 服务器端逻辑
          userData = req && req.cookies ? req.cookies.userData : null;
        } else {
          // 客户端逻辑
          const storedUserData = getCookie('userData');
          userData = storedUserData ? JSON.parse(storedUserData) : null;
        }

        if (userData) {
          await dispatch('loginUser', userData);
        }
        commit('setUserDataChecked', true);
      }
    },
    getters: {
      isLoggedIn: state => !!state.user,
      currentUser: state => state.user,
      authToken: state => state.authToken,
      isUserDataChecked: state => state.userDataChecked
    }
  });
}

// module.exports = {
//   createStore
// }
