import Vue from 'vue'
import VueMeta from 'vue-meta'
import { createApp } from './app';
import { loadLanguageAsync } from './lang'; 


Vue.use(VueMeta)

const { app, store, router, i18n } = createApp();

// 初始化 store 状态
if (window.__INITIAL_STATE__) {
  store.replaceState(window.__INITIAL_STATE__)
  const { i18nState } = window.__INITIAL_STATE__
  i18n.locale = i18nState.locale
  i18n.setLocaleMessage(i18nState.locale, i18nState.messages)
  // console.log("client entry 333, i18n locale:", i18n.locale)
  // console.log("client entry 444, i18n locale:", i18n.getLocaleMessage(i18n.locale))
} else {
   // 如果没有初始状态，加载默认语言
  const defaultLang = 'en' // 或者您的默认语言
  await loadLanguageAsync(i18n, defaultLang)
}

router.onReady(() => {
  app.$mount('#app');
});
