import Vue from 'vue';
import VueMeta from 'vue-meta'

import { ErrorReporterPlugin } from './utils/errorReporter'

import createRouter from './router';
import headMixin from './utils/head';
import { createStore } from './store';
import { createI18n } from './lang';


import App from './App.vue';

// 注册错误上报插件
Vue.use(ErrorReporterPlugin);

Vue.mixin(headMixin);
Vue.use(VueMeta, { 
  refreshOnceOnNavigation: true
});

export function createApp() {
  const store = createStore();
  const router = createRouter(store);
  const i18n = createI18n();

  const app = new Vue({
    store,
    router,
    i18n,
    render: h => h(App),
    metaInfo: {
      meta: [
        { charset: 'utf-8' }
      ]
    }
  });
  
  return { app, store, router, i18n };
}
